.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  max-width: 1100px;
  width: 100%;
  padding: 20px;
  margin-top: 120px;
}

.contact-container h1 {
  margin-bottom: 50px;
  font-size: 45px;
}
.input-box {
  display: flex;
  flex-direction: column;
}

.input-box input,
.input-box textarea {
  width: 90%;
  font-size: 20px;
  padding: 10px 10px;
}

.input-box span {
  font-size: 25px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 500px;
  padding: 20px;
}

.Submit-btn {
  font-size: 18px;
  color: white;
  margin-top: 20px;
  background-color: black;
  padding: 15px 20px;
  border-radius: 10px;
  border: none;
  margin-bottom: 100px;
  transition: all 0.2s ease-in-out;
}

.Submit-btn:hover {
  background-color: rgb(231, 231, 3);
  color: black;
}
