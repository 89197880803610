.fleet-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
}

.fleet-container h2 {
  font-size: 50px;
}

.text-box {
  max-width: 800px;
  width: auto;
  text-align: center;
  margin: 0 50px;
}

.nav-container {
  display: flex;
  margin-top: 50px;
  gap: 5px;
}

.fleet-btn {
  color: black;
  padding: 10px 15px;
  transition: all 0.2s ease-in-out;
}
.fleet-btn:hover {
  background-color: rgb(236, 236, 3);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.fleet-btn-click {
  background-color: black;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  padding: 10px 15px;
}
