.about-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  max-width: 1100px;
  width: 100%;
  height: 100vh;
}

.car-img-box img {
  max-width: 450px;
  width: 100%;
  height: auto;
  z-index: 5;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.car-img-box {
  position: relative;
}

.car-img-box span {
  position: absolute;
  top: -5%;
  left: 50%;
  max-width: 300px;
  width: 100%;
  height: 500px;
  background-color: black;
  border-radius: 20px;
  z-index: -1;
}

.content-box h2 {
  font-size: 35px;
  margin-bottom: 25px;
}
.content-box h3 {
  font-size: 25px;
  color: rgb(247, 247, 7);
}

/* ===================== MEDIA QUERIES ========================== */

@media (width <= 1050px) {
  .about-container {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  .car-img-box {
    margin: 80px 0 100px;
    width: auto;
    max-width: 300px;
  }
  .car-img-box img {
    width: 100%;
  }
  .car-img-box span {
    width: 170px;
    height: 335px;
  }
}
