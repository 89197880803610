.home-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  max-width: 1100px;
  width: 100%;
  height: 100vh;
}

.car-img-container img {
  max-width: 700px;
  width: 100%;
  height: auto;
  margin-right: -100px;
}

.content-box h1 {
  font-size: 80px;
  margin-bottom: 50px;
  line-height: 90px;
}

.content-box {
  max-width: 450px;
  padding: 15px;
}

/* ===================== MEDIA QUERIES ========================== */

@media (width <= 1050px) {
  .home-container {
    display: flex;
    flex-direction: column;
  }
  .content-box {
    text-align: center;
    margin-top: 80px;
  }
}

@media (width <= 600px) {
  .content-box h1 {
    font-size: 50px;
  }
  .content-box {
    max-width: 320px;
  }
}
