.car-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
}

.detail-img {
  max-width: 800px;
  width: 100%;
  height: auto;
}

.detail-img img {
  width: 100%;
}

.car-detail-content {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  gap: 20px;
}

.car-detail-content h3 {
  font-size: 30px;
  text-align: center;
}

.car-detail-container button {
  font-size: 18px;
  color: white;
  margin-top: 20px;
  background-color: black;
  padding: 15px 20px;
  border-radius: 10px;
  border: none;
  margin-bottom: 100px;
  transition: all 0.2s ease-in-out;
}

.car-detail-container button:hover {
  background-color: rgb(231, 231, 3);
  color: black;
}
